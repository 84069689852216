import React from 'react';
// import '../src/App.css';
import EmailSignature from "./component/as10";

const App = () => {
    return (
        <EmailSignature/>
    )
};

export default App;